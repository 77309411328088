h3 {
  color: #000;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: 8px;
  text-transform: uppercase;
  text-align: center;
}

p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 2px;
}

.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-arrow.slick-next {
  right: -50px;
}

.slick-arrow.slick-prev {
  left: -50px;
}

.testimonial .slick-dots li button::before {
  font-size: 12px;
  color: #000;
}

.testimonial .slick-dots li {
  top: 20px;
}

.cardTextContainer {
  width: 80%;
}

@media screen and (max-width: 768px) {
  h3 {
    font-size: 40px;
    letter-spacing: 6px;
  }

  .cardTextContainer {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  h3 {
    font-size: 32px;
    letter-spacing: 6px;
  }

  p {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
  }

  .cardTextContainer {
    width: 100%;
  }
}
