* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background: #fafbfc;
  margin: 0px;
  padding: 0px;
}

p {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.43 !important;
  letter-spacing: 1px !important;
}

.mainRaised {
  background-color: #fafbfc;
  padding: 0px 20px 0px;
  padding-bottom: 60px;
  margin: auto;
  max-width: 1500px;
}

@media screen and (max-width: 768px) {
  .mainRaised {
    padding: 0px 20px 0px;
  }
}

@media screen and (max-width: 480px) {
  .mainRaised {
    padding: 0px 20px 0px;
  }
}
